.circle-Table {
  border-collapse: separate;
  border-spacing: 0 5px;
  width: 75%;
  margin-left: 10%;
  margin-right: 10%;
}

.circle-filter-input {
  width: 20%;
  margin: 0 10%;
  padding: 5px;
  margin-bottom: 10px;
}

.table-header {
  background-color: #ffc15e;
  padding-left: 5%;
  text-align: center;
  box-shadow: 7px 3px 3px #e0d4cc;
}

.circle-table-row-style {
  padding-left: 5%;
  border: 1px solid #fcf5f0;
  border-top: 1px solid #fcf5f0;
  text-align: center;
  box-shadow: 7px 3px 3px #e0d4cc;
  background-color: #ffffff;
}

.manuscript-table-row-style {
  padding-left: 5%;
  border: 1px solid #fcf5f0;
  border-top: 1px solid #fcf5f0;
  text-align: center;
  box-shadow: 7px 3px 3px #e0d4cc;
  background-color: #ffffff;
}

.manuscript-table-row-style:hover {
  cursor: pointer;
  box-shadow: 9px 4px 4px #d3c8c1;
}
