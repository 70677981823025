.MuiSnackbar-root {
  margin-left: 5rem;
}

@media only screen and (max-width: 600px) {
  .MuiSnackbar-root {
    margin-bottom: 5rem;
    margin-left: 0;
  }
}
