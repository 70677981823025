#content-header {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 100;
  height: 50px;
  background-color: #ffffff;
  box-shadow: 7px 3px 3px #e0d4cc;
}

#content-header > * {
  flex: 0 0 calc(100% / 3);
}

.header-back-btn-box {
  display: flex;
  align-items: center;
}

.header-back-btn-icon {
  margin-left: 5px;
  font-size: 20px;
  color: black;
}

.header-title-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-title {
  font-weight: 600;
  font-size: 28px;
}

.header-profile-container {
  padding-right: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.header-avatar-image {
  cursor: pointer;
  border-radius: 50%;
  width: 36px;
}

.header-username {
  font-weight: 600;
}

/* Mui */

#content-header .MuiBadge-badge {
  scale: 0.9;
  top: 10px;
  right: 4px;
}

/* Notifications Modals Below */

.notification-container {
  margin: 5px 0;
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
}

.complete-notification-btn {
  cursor: pointer;
}

.notification-handle-actions-btn {
  cursor: pointer;
}

.notification-action-text {
  padding: 10px 0;
}

.notification-action-buttons-box {
  display: flex;
  justify-content: space-between;
}

.notification-accept-btn,
.notification-reject-btn {
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
}

.notification-accept-btn {
  background-color: lightgreen;
}

.notification-reject-btn {
  background-color: rgb(255, 79, 79);
  color: #f0f0f0;
}

/* Small Screen - Phones */
@media only screen and (max-width: 800px) {
  #content-header > * {
    flex: none;
  }

  .header-profile-container {
    margin-left: auto;
  }

  .header-title {
    font-size: 22px;
  }
}
