/* --------------------------------------------------------------------------------
PLEASE DO NOT TOUCH!!!
-------------------------------------------------------------------------------- */

/* * {
    border: black solid 1px;
} */

body,
html,
#react-root {
  height: 100%;
  box-sizing: border-box;
}

html {
  background-color: #fcf5f0;
}

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin: 0px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

/* --------------------------------------------------------------------------------
Resetting Default Formatting
-------------------------------------------------------------------------------- */

img {
  display: inline-block;
  max-width: 100%;
}

a:link,
a:visited,
a:hover,
a:active {
  color: black;
  text-decoration: none;
}

input,
button,
textarea,
select {
  font: inherit;
}

p {
  color: #373f51;
}
h1 {
  color: #373f51;
}
h2 {
  color: #373f51;
}
h3 {
  color: #373f51;
}
h4 {
  color: #373f51;
}
h5 {
  color: #373f51;
}
h6 {
  overflow-wrap: break-word;
  margin: 0;
  color: #373f51;
}

/* --------------------------------------------------------------------------------
Alert
-------------------------------------------------------------------------------- */

.alert {
  box-sizing: border-box;
  padding: 15px 10px;
  margin: 15px 0;
  border: 2px solid #e01616;
  background-color: #efefef;
  border-radius: 3px;
  color: #e01616;
  font-size: 0.8rem;
}

/* --------------------------------------------------------------------------------
Main Content
-------------------------------------------------------------------------------- */

.content-main {
  margin-left: 5rem;
  height: 100vh;
  width: calc(100% - 5rem);
  position: fixed;
  overflow: scroll;
}

@media only screen and (max-width: 600px) {
  .content-main {
    margin-left: 0;
    width: 100%;
    padding-bottom: 5rem;
  }
}
