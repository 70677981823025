.ManuscriptListHeader {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr 1fr;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 1%;
  background-color: #ffc15e;
  box-shadow: 7px 3px 3px #e0d4cc;
}

.sub-header-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin: 2%;
}

.inline-flex-wrapper {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 12px;
}

.manuscript-list-text-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  margin-right: 10%;
  background-color: #ffffff;
  margin-top: 4px;
  margin-bottom: 4px;
  padding-left: .5%;
  box-shadow: 7px 3px 3px #e0d4cc;
}

.manuscript-list-text-wrapper:hover {
  cursor: pointer;
  box-shadow: 9px 4px 4px #d3c8c1;
}

.manuscript-list-title {
    text-decoration: underline;
    margin-top: 5px;
    margin-bottom: 0px;
}

.manuscript-list-author {
    margin-top: 5px;
    margin-bottom: 0px;
}

.manuscript-list-button-wrapper {
    display: flex;
    flex-direction: row;
    margin-left: 10%;
    margin-bottom: 12px;
}

.title {
  display: flex;
  flex-flow: column wrap;
  margin: 5px;
  padding-left: 1%;
  grid-column: 1/2;
  align-self: center;
}

.author {
  margin: 0px;
  grid-column: 2/3;
  align-self: center;
  display: flex;
  flex-flow: column wrap;
}

.preview {
  display: flex;
  flex-flow: column wrap;
  align-self: center;
  margin-right: 5px;
  padding-right: 2%;
  grid-column: 3/4;
  grid-row: 1/3;
}

.writersDesk-button-wrapper {
  margin-left: 10%;
}

.manuscript {
  background-color: #ffffff;
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 10px;
  padding-top: .5%;
  padding-bottom: .5%;
  padding-left: 2%;
  padding-right: 2%;
}

